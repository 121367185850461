import 'maplibre-gl/dist/maplibre-gl.css';

import './global.scss';
import './tailwind.css';

import {Suspense, lazy} from 'react';
import {memo} from 'react';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';

const EmbeddedAnalytics = lazy(() => import('subApps/EmbeddedAnalytics'));
const AppRoot = lazy(() => import('subApps/AppRoot'));

function App() {
  return (
    <Router>
      <Suspense>
        <Routes>
          <Route element={<AppRoot />} path="*" />
          <Route element={<EmbeddedAnalytics />} path="/embedded-analytics" />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default memo(App);
